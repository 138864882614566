/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();

        $('body').css('opacity', 1);

        $('.actionDelete').click(this.deleteElement);

        console.log('App initialized');
    }

    deleteElement()
    {
        if (confirm('Are you sure you want to delete this item?')) {
            const entityType = $(this).data('entityType'),
                entityId = $(this).data('entityId'),
                entityParent = $(this).parents('.deleteParent');

            if (entityType && entityId) {
                $.ajax({
                    url: '/?action=portal/utilities/delete-element',
                    data: {
                        entityType,
                        entityId
                    },
                    method: 'post',
                    dataType: 'json',
                    headers: {
                        "X-CSRF-Token": window.Globals.csrfToken
                    }
                }).done(() => {
                    entityParent.hide();
                }).fail(() => {
                    alert('Could not delete this item');
                });
            }
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    addToast(message, type = "success", autoHide = true)
    {
        let _class = "toast align-items-center fs-6 border-0 mt-2 bg-" + type;
        let toast = $('<div class="' + _class + '" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="' + (autoHide ? 'true' : 'false') + '"><div class="d-flex"><div class="toast-body">' + message + '</div><button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div></div>');
        toast.appendTo($('#global-messages'));
        this.initToasts(toast);
    }
}

export default App;
